<template>
  <div class="my-3 mx-3">
    <div
      class="card smcard"
      :class="{
        'pcard-dark': nightMode,
        pcard: !nightMode,
        'bg-dark3': nightMode,
      }"
    >
<!--      <div style="height: 180px;">-->
<!--        <img-->
<!--          class="card-img-top"-->
<!--          :src="projects.pictures[0].img"-->
<!--          alt="Card image cap"-->
<!--        />-->
<!--      </div>-->
      <div class="card-body pborder-top">
        <h2 class="title2">{{ projects.name }}</h2>
        <div>
          <div class="pb-1 bheight">
            <span
              class="badge mr-2 mb-2 "
              v-for="tech in projects.topics"
              :key="tech"
              :class="{ 'bg-dark4': nightMode }"
              >{{ tech }}
            </span>
          </div>
          <p
            class="title3 m-0 pb-2 pheight pt-1"
            v-html="
              projects.description && projects.description.length > 100
                ? projects.description.substring(0, 105) + '...'
                : projects.description
            "
          >
          </p>
        </div>
        <div class="mt-2">
          <a
            :href="projects.svn_url + '/archive/master.zip'"
            class="text-center btn-sm btn btn-outline-secondary no-outline"
            @click.prevent="open(projects.svn_url + '/archive/master.zip')"
          >
	          <i class="fab fa-github" /> Clone Project
          </a>
          <a
		        :href="projects.html_url"
            class="text-center btn-sm btn btn-outline-secondary no-outline"
            v-if="projects.url"
            @click.prevent="open(projects.html_url)"
		        target=" _blank"
          >
	          <i class="fab fa-github" /> Repo
          </a>
        </div>
	      <hr>
	      <div class="pb-3">Languages:
            <a
		            class="badge badge-light card-link"
		            v-for="(percent, name) in projects.languages_percent"
		            :key="percent"
		            :href="projects.html_url+ '/search?l=' + name"
		            target=" _blank"
            >{{ name }}: {{((percent/projects.total_languages_percent)*100).toFixed(2)}}%
            </a>
	      </div>
	      <div class="card-text">
		      <a
				    :href="projects.html_url + '/stargazers'"
		        target=" _blank"
				    class="text-reset"
		      >
		        <span class="card-link mr-4">
              <i class="fab fa-github" /> Stars
              <span class="badge badge-dark">{{projects.stargazers_count}}</span>
            </span>
		      </a>
		      <div>
			      <small class="">Updated {{setUpdatedAt()}}</small>
		      </div>
	      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Object,
    },
    nightMode: {
      type: Boolean,
    },
  },
  methods: {
    open(url) {
      window.open(url, "_blank");
    },
	  setUpdatedAt() {
		  const date = new Date(this.projects.updated_at);
		  const nowdate = new Date();
		  const diff = nowdate.getTime() - date.getTime();
		  const hours = Math.trunc(diff / 1000 / 60 / 60);
		  if (hours < 24) {
			  if (hours < 1)  "just now";
			  let measurement = hours === 1 ? "hour" : "hours";
			  return (`${hours.toString()} ${measurement} ago`);
		  } else {
			  const options = { day: "numeric", month: "long", year: "numeric" };
			  const time = new Intl.DateTimeFormat("en-US", options).format(date);
			  return (`on ${time}`);
		  }
	  }
  },
};
</script>

<style scoped>
img {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.img-div img {
  /* object-fit: cover;
    overflow: hidden; */
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* object-position: 50% 120%;
    max-width: 300px !important; */
}

.bheight {
  height: 35px;
  overflow: auto;
}

.pheight {
  height: 90px;
  max-height: 130px;
  overflow: auto;
  text-align: justify;
}

div.img-div {
  position: absolute;
  width: 100%;
  height: 100%;
}
.pborder-top {
  border-top: 1px solid rgb(193, 193, 193);
}

.pcard {
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  border: none;
  box-shadow: 1px 1px 12px rgb(233, 233, 233);
  transition: all 0.5s;
  height: 450px;
}

.pcard:hover {
  transition: all 0.5s;
  /* cursor: pointer; */
  box-shadow: 1px 1px 15px rgb(216, 216, 216);
}

.pcard-dark {
  border-radius: 7px;
  border: none;
  background-color: #30363a !important;
  /* box-shadow: 1px 1px 12px rgb(53, 53, 53); */
  transition: all 0.5s;
  height: 460px;
}

.pcard-dark:hover {
  transition: all 0.5s;
  /* cursor: pointer; */
  box-shadow: 1px 1px 12px rgb(53, 53, 53);
}

.title2 {
  font-size: 20px;
  font-weight: 400;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.badge {
  background-color: rgb(211, 227, 233);
  transition: all 0.5s;
  font-weight: 500;
  font-size: 13px;
}

.btn:hover {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.btn:focus {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.bg-dark3 {
  background-color: rgb(82, 82, 82);
}

.bg-dark4 {
  background-color: #494e55 !important;
}
.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.badge-light {
	color: #212529;
	background-color: #f8f9fa;
}
.badge-dark {
	color: #fff;
	background-color: #343a40;
}
.card-text:last-child {
	margin-bottom: 0;
}
.pb-3 {
	padding-bottom: 1rem!important;
}
.btn-outline-secondary {
	color: #f8f9fa;
	background-color: #343a40;
}
.text-light .btn-outline-secondary {
	color: #343a40;
	background-color: #f8f9fa;
}

</style>
