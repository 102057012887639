<template>
  <div id="app":class="{ 'text-dark': !nightMode, 'text-light': nightMode }">
	  <Navbar @scroll="scrollTo" @nightMode="switchMode" :nightMode="nightMode" />
	  <div class="parent">
		  <Home :nightMode="nightMode" />
		  <About id="about" :nightMode="nightMode" />
		  <Skills id="skills" :nightMode="nightMode" />
		  <projects id="projects" :nightMode="nightMode" />
<!--		  <Recommendation :nightMode="nightMode" />-->
		  <Contact id="contact" :nightMode="nightMode" />
		  <Footer :nightMode="nightMode" />
	  </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Home from "./components/Home.vue";
import About from "./components/About.vue";
import Skills from "./components/Skills.vue";
import projects from "./components/Projects.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";

import info from "../info";

export default {
  name: 'App',
	components: {
		Navbar,
		Home,
		About,
		Skills,
		projects,
		Contact,
		Footer,
	},
  data () {
    return {
	    nightMode: false,
	    config: info.config,
    };
  },
	created() {
		if (this.config.use_cookies) {
			this.nightMode = this.$cookie.get("nightMode") === "true" ? true : false;
		}
	},
	mounted() {
		["about", "contact", "skills", "projects"].forEach((l) => {
			if (window.location.href.includes(l)) {
				var elementPosition = document.getElementById(l).offsetTop;
				window.scrollTo({ top: elementPosition - 35, behavior: "smooth" });
			}
		});
	},
	methods: {
		switchMode(mode) {
			if (this.config.use_cookies) {
				this.$cookie.set("nightMode", mode);
			}
			this.nightMode = mode;
		},
		scrollTo(ele) {
			if (ele == "home") {
				this.$router.push(`/`);
				window.scrollTo({ top: -80, behavior: "smooth" });
			} else {
				var elementPosition = document.getElementById(ele).offsetTop;
				window.scrollTo({ top: elementPosition - 35, behavior: "smooth" });
				if (this.$router.history.current.path !== `/${ele}`)
					this.$router.push(`/${ele}`);
			}
		},
	},
}
</script>

<style scoped>
#app {
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	width: 100%;
}

@media screen and (max-width: 580px) {
	#app {
		width: fit-content;
	}
}

.parent {
	margin-top: 38px;
	padding-top: 40px;
	position: relative;
}

.text-light {
	color: #d3d2d2 !important;
}
/* To set scrollbar width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 9px;
	border: 2px solid white; /* Use your background color instead of White */
	background-clip: content-box;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 9px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

img {
	max-width: 100%;
	display: block;
}
</style>
