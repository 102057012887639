<template>
  <div
    :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }"
    class="pt-5 p-st"
  >
    <div
      class="container aos-init aos-animate"
      data-aos="fade"
      data-aos-once="true"
      data-aos-duration="100"
    >
      <div class="row align-items-center">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
	        <picture>
		        <source :srcset="picture_desk" media="(min-width: 992px)">
		        <source  :srcset="picture_desk" media="(min-width: 768px)">
		        <source  :srcset="picture_mob" media="(min-width: 0px)">
		        <img :src="picture_desk" :alt="flat_picture_alt" :title="flat_picture_alt" :height="desktop_height" :width="desktop_width">
	        </picture>
        </div>
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-5">
          <span
            class="home-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
            >hello there!</span
          >
          <div class="description">
            <h1
                v-html="description1"
                class="home-desc">
            </h1>
            <h2
                v-html="description2"
                class="home-desc">
            </h2>
            <h3
                v-html="description3"
                class="home-desc">
            </h3>
          </div>
	        <div class="text-center pb-4">
		        <a
				        :href="this.linkedin"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2 "
				        aria-label="My LinkedIn"
		        >
			        <i class="fab fa-linkedin"></i>
		        </a>
		        <a
				        :href="this.github"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2"
				        aria-label="My GitHub"
		        >
			        <i class="fab fa-github"></i>
		        </a>
		        <a
				        :href="this.resume"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2"
				        aria-label="My Resume"
		        >
			        <i class="fa fa-address-book"></i>
		        </a>
		        <a
				        :href="this.instagram"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2"
				        aria-label="My Instagram"
		        >
			        <i class="fab fa-instagram"></i>
		        </a>
		        <a
				        :href="this.facebook"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2"
				        aria-label="My Facebook"
		        >
			        <i class="fab fa-facebook"></i>
		        </a>
		        <a
				        :href="this.twitter"
				        target="_blank"
				        class="btn btn-outline-secondary mx-2"
				        aria-label="My Twitter"
		        >
			        <i class="fab fa-twitter"></i>
		        </a>
	        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "Home",
  components: {
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
	    flat_picture_alt: info.my_image.flat_picture_alt,
	    picture_mob: info.my_image.flat_picture_mobile_url,
	    picture_desk: info.my_image.flat_picture_desktop_url,
	    desktop_height: info.my_image.desktop_height,
	    desktop_width: info.my_image.desktop_width,
	    mobile_height: info.my_image.mobile_height,
	    mobile_width: info.my_image.mobile_width,
      description1: info.description1,
      description2: info.description2,
      description3: info.description3,
      name: info.name,
      linkedin: info.links.linkedin,
      github: info.links.github,
      angellist: info.links.angellist,
      resume: info.links.resume,
	    instagram: info.links.instagram,
	    facebook: info.links.facebook,
	    twitter: info.links.twitter,
    };
  },
  methods: {
    // open(link) {
    //   switch (link) {
    //     case "linkedin":
    //       window.open(this.linkedin, "_blank");
    //       break;
    //     case "github":
    //       window.open(this.github, "_blank");
    //       break;
    //     case "angellist":
    //       window.open(this.angellist, "_blank");
    //       break;
    //     case "resume":
    //       window.open(this.resume, "_blank");
    //       break;
    //   }
    // },
  },
};
</script>

<style scoped>
.home-title {
  font-size: 28px;
  font-weight: 500;
}
.home-desc {
  font-size: 1rem;
  text-align: justify;
  font-weight: 400;
}

img {
  max-width: 300px;
  margin-top: 60px;
  transform: rotateY(180deg);
}

@media only screen and (max-width: 580px) {
  img {
    object-fit: cover;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgb(205, 205, 205);
  }
}

.fa {
  font-size: 15px;
}

.btn {
  border-color: #003939;
  color: #003939;
}
.text-light .btn {
	border-color: #669db3ff;
	color: #669db3ff;
}

.btn:hover {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.btn:focus {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.btn:focus {
  outline: none !important;
}

p {
  text-align: justify;
  font-weight: 400;
}
.text-dark .description {
	color: #000!important;
}
/* LEAVES */
</style>
