<template>
  <div
    class="py-4 p-st"
    :class="{
      'bg-light': !nightMode,
      'bg-dark': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="100"
      >
        <span
          class="title text-center aos-init aos-animate"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >Projects.</span
        >
      </div>
      <hr
        width="50%"
        :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
	    <div class="row">
		    <div
				    class="col-xl-4 col-bg-4 col-md-6 col-sm-12"
				    v-for="(projects, idx) in github"
				    :key="projects.name"
		    >
			    <Card
					    :style="{ 'transition-delay': (idx % 3) / 6.2 + 's' }"
					    :projects="projects"
					    data-aos="fade-up"
					    :nightMode="nightMode"
					    data-aos-offset="100"
					    data-aos-delay="10"
					    data-aos-duration="100"
					    data-aos-easing="ease-in-out"
					    data-aos-mirror="true"
					    data-aos-once="true"
			    />
		    </div>
	    </div>
    </div>
  </div>
</template>

<script>
import Card from "./helpers/Card.vue";
import info from "../../info";

export default {
  name: "projects",
  components: {
    Card,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      all_info: info.projects,
      desgin_info: info.projects_design,
      projects_info: [],
      showModal: false,
      showDesignModal: false,
      modal_info: {},
      design_modal_info: {},
      number: 3,
      showBtn: "show more",
      shower: 0,
      data: [
        '<div class="example-slide">Slide 1</div>',
        '<div class="example-slide">Slide 2</div>',
        '<div class="example-slide">Slide 3</div>',
      ],
	    github: null,
	    githubSpecific: null
    };
  },
  created() {
	  // for (var i = 0; i < this.number; i++) {
		//   this.projects_info.push(this.all_info[i]);
	  // }
	  this.fetchGithubData();
  },
	serverPrefetch() {
		// component is rendered as part of the initial request
		// pre-fetch data on server as it is faster than on the client
		//return this.fetchGithubData()
	},
	beforeCreate() {
		//this.fetchGithubData();
	},
  watch: {
    // number() {
    //   this.projects_info = [];
    //   for (var i = 0; i < this.number; i++) {
    //     this.projects_info.push(this.all_info[i]);
    //   }
    // },
  },
  methods: {
	  async fetchGithubData() {
		  const API = "https://api.github.com";
		  const username = process.env.GITHUB_USERNAME;
		  const allReposAPI = `${API}/users/${username}/repos?sort=updated&direction=desc`;
		  const response = await fetch(allReposAPI)
		  if (response.status === 200 ) {
			  const data = await response.json()

			  const specficReposAPI = `${API}/repos/${username}`;
			  let count = 0;
			  let total = 0;
			  for (let repoName of data) {

				  const response1 = await fetch(`${specficReposAPI}/${repoName.name}/languages`);
				  const data1 = await response1.json()
				  data[count].languages_percent = data1
				  for (let percent in data1) {
					  total = total + data1[percent]
					  data[count]["total_languages_percent"] = total

				  }
				  count++
			  }
			  this.$set(this, "github", data);
		  }
	  },
    // next() {
    //   this.$refs.flickity.next();
    // },

    // previous() {
    //   this.$refs.flickity.previous();
    // },
    // showDesignModalFn(design_projects) {
    //   this.design_modal_info = design_projects;
    //   this.showDesignModal = true;
    // },
    // showMore() {
    //   if (this.number != this.all_info.length) {
    //     this.number += 3;
		//
    //     window.scrollBy({
    //       top: document.getElementsByClassName("smcard")[0].clientHeight,
    //       behavior: "smooth",
    //     });
		//
    //     if (this.number > this.all_info.length)
    //       this.number = this.all_info.length;
    //   }
		//
    //   if (this.number == this.all_info.length && this.shower == 0) {
    //     this.shower = 1;
    //     this.showBtn = "show less";
    //   } else if (this.number == this.all_info.length && this.shower == 1) {
    //     var elementPosition = document.getElementById("projects").offsetTop;
    //     window.scrollTo({ top: elementPosition + 5, behavior: "smooth" });
    //     this.shower = 0;
    //     this.number = 3;
    //     this.showBtn = "show more";
    //   }
    // },
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}

.dimg:hover .middle {
  opacity: 1;
}

</style>
