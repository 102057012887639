import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import AOS from 'aos'
import VueScrollTo from 'vue-scrollto'
var VueCookie = require('vue-cookie');

//import 'bootstrap/js/dist/button';
//import 'bootstrap/js/dist/collapse';

import "../scss/main.scss"

// Vue.use(VTooltip)
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(VueCookie);


Vue.config.productionTip = false
//import * as serviceWorker from "../assets/service-worker.js";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

const routes = [
  { path: '/' },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


new Vue({
  el: '#app',
  router,
  render: h => h(App),
  created () {
    AOS.init()
  },
  mounted () {
  // You'll need this for renderAfterDocumentEvent.
  document.dispatchEvent(new Event('render-event'))
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
          // Registration was successful
          // console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
          // registration failed :(
          // console.log('ServiceWorker registration failed: ', err);
        });
      });
    }
  }
})
