let info = {
  name: "Kshitij Joshi",
  logo_name: "Kshitij Joshi",
  my_image: {
    flat_picture_desktop_url: require("./src/assets/kshitij-desk.webp"),
    flat_picture_mobile_url: require("./src/assets/kshitij-mob.webp"),
    desktop_height: "375px",
    desktop_width: "300px",
    mobile_height: "200px",
    mobile_width: "200px",
    flat_picture_alt: "Kshitij's Photo",
  },
  config: {
    use_cookies: true,
    navbar: {
      blur: true
    }
  },
  description:
      "Hi there! I'm Kshitij, a Software Engineer working as a Senior Cloud Platform Engineer at Clover. I am tech-savvy & passionate about developing good quality, maintainable, and innovative solutions in the least amount of time. I like to learn and play around with new technologies & widen my knowledge about existing ones. I have exposure to all phases of the Software Development Life Cycle (SDLC) and technology-driven solutions in collaboration with teams. I have also worked on Agile Development and Test Driven Development. My career goal is to make a positive contribution to the IT-world no matter how small it is.<br><br>In my personal time, I love to play around with my gaming rig, host services on my home server and play around with networking devices like routers.<br><br>When I'm not in front of a terminal, I'm probably hiking, skiing or playing soccer.",
  longDesc: "Hi there! I'm Kshitij Joshi, a Software Engineer working as a Senior Cloud Platform Engineer at Clover. Before this, I have worked as a senior Platform/DevOps/Site Reliability Engineer(SRE) at Howl and a Tech Lead(DevOps Platform Engineer) at WebMD. I am tech-savvy & passionate about developing good quality, maintainable, and innovative solutions in the least amount of time. I like to learn and play around with new technologies & widen my knowledge about existing ones. I have exposure to all phases of the Software Development Life Cycle (SDLC) and technology-driven solutions in collaboration with teams. I have also worked on Agile Development and Test Driven Development. My career goal is to make a positive contribution to the IT-world no matter how small it is.<br><br>In my personal time, I love to play around with my gaming rig, host services on my home server and play around with networking devices like routers. When I'm not in front of a terminal, I'm probably hiking, skiing or playing soccer.",
  description1: "I'm Kshitij, a SWE working as a Senior Cloud Platform Engineer at Clover.",
  description2:
      "I am tech-savvy & passionate about developing good quality, maintainable, and innovative solutions in the least amount of time. I like to learn and play around with new technologies & widen my knowledge about existing ones. I have exposure to all phases of the Software Development Life Cycle (SDLC) and technology-driven solutions in collaboration with teams. I have also worked on Agile Development and Test Driven Development. My career goal is to make a positive contribution to the IT-world no matter how small it is.",
  description3:
      "<br><br>In my personal time, I love to play around with my gaming rig, host services on my home server and play around with networking devices like routers.<br><br>When I'm not in front of a terminal, I'm probably hiking, skiing or playing soccer.",
  links: {
    linkedin: "https://www.linkedin.com/in/kshitijmjoshi/",
    github: "https://github.com/kshitijmjoshi",
    resume: "https://static.kshitij-joshi.com/Kshitij_Resume_2023.pdf",
    instagram: "https://www.instagram.com/blue4life__/",
    facebook: "https://www.facebook.com/kshitijmjoshi",
    twitter: "https://twitter.com/kshitijchelsea",
    email_id: "hello@kshitij-joshi.com"
  },
  education: [
    {
      name: "New Jersey Institute of Technology",
      place: "USA",
      date: "Aug, 2016 - May, 2018",
      degree: "Masters in Computer Science",
      gpa: "3.7/4.0",
      images: {
        alt: "NJIT Logo",
        image_url: require("./src/assets/njit.webp"),
        width: "250px",
        height: "112px"
      },
      description:
        "Completed Master's in Computer Science.",
      skills: [
        "Software Engineering",
        "Algorithms",
        "OS"
      ]
    },
    {
      name: "University of Mumbai",
      place: "India",
      date: "Aug, 2012 - July, 2016",
      degree: "Bachelors in Computer Engineering",
      gpa: "6.7/10.0",
      images: {
        alt: "University of Mumbai Logo",
        image_url: require("./src/assets/mumbai.webp"),
        width: "250px",
        height: "99px"
      },

      description:
        "Completed Bachelor's in Computer Science.",
      skills: [
        "Software Engineering"
      ]
    }
  ],
  experience: [
    {
      name: "Clover",
      place: "New Jersey",
      date: "Jan 2024 - Present",
      position: "Senior Cloud Platform Engineer",
      // description:
      //     "Improved SDLC by enhancing CI/CD, documenting & introducing best practices, developing automation & IaC (infrastructure-as-code), monitoring, metrics & logging resulting in an improved observability & incident response and cost optimization and improving collaboration between SRE & SWE.",
      // skills: ["AWS", "Docker", "Kubernetes", "Terraform", "DataDog", "Python", "Cloudwatch", "ECS", "EKS", "Elasticsearch", "Kibana", "AWS Lambda", "Cloudformation", "ArgoCD", "GitHub Actions", "Bash"],
      images: {
        alt: "Clover Logo",
        image_url: require("./src/assets/clover.webp"),
        width: "69px",
        height: "69px"
      },
    },
    {
      name: "Howl",
      place: "New York City",
      date: "March 2022 - Jan 2024",
      position: "Senior DevOps/Platform/Site Reliability Engineer",
      description:
          "Improved SDLC by enhancing CI/CD, documenting & introducing best practices, developing automation & IaC (infrastructure-as-code), monitoring, metrics & logging resulting in an improved observability & incident response and cost optimization and improving collaboration between SRE & SWE.",
      skills: ["AWS", "Docker", "Kubernetes", "Terraform", "DataDog", "Python", "Cloudwatch", "ECS", "EKS", "Elasticsearch", "Kibana", "AWS Lambda", "Cloudformation", "ArgoCD", "GitHub Actions", "Bash"],
      images: {
        alt: "Howl Logo",
        image_url: require("./src/assets/howl.webp"),
        width: "69px",
        height: "69px"
      },
    },
    {
      name: "WebMD",
      place: "New York City",
      date: "July 2021 – March 2022",
      position: "Tech Lead",
      description:
        "Managing WebMD's Helios Platform and offering the Platform as a service. Reduce Distance Between Developers & Operations by leading the DevOps team by introducing DevOps methodologies into other teams.",
      skills: ["Docker", "Kubernetes", "OpenResty", "Nginx", "Cloudflare", "Node.js", "Gitlab CI/CD", "Redis", "PostgresSQL", "Grafana"],
      images: {
        alt: "WebMD Logo",
        image_url: require("./src/assets/webmd.webp"),
        width: "250px",
        height: "69px"
      },
    },
    {
      name: "WebMD",
      place: "New York City",
      date: "Dec 2019 – July 2021",
      position: "Sr. Platform Engineer",
      description:
        "Build WebMD's new Container based Helios Platform hosted on Kubernetes.",
      skills: ["Docker", "Kubernetes", "OpenResty", "Nginx", "Cloudflare", "Node.js", "Gitlab CI/CD", "Redis", "PostgresSQL"]
    },
    {
      name: "WebMD",
      place: "New York City",
      date: "March 2019 – Dec 2019",
      position: "Web Developer",
      description:
        "Designed the WebMD’s new Runtime with Docker & container-based system running on Kubernetes in favor of legacy systems improving build time, development time & page performance by 100%.",
      skills: ["VueJs", "Node.js", "Gitlab CI/CD"]
    },
    {
      name: "WebMD",
      place: "New York City",
      date: "June 2018 – March 2019",
      position: "Associate Web Developer",
      description:
        "Re-platformed Vitals (WebMD’s acquisition) into the WebMD infrastructure.",
      skills: ["VueJs", "Node.js", "Gitlab CI/CD", "PHP", "Jenkins"]
    },
    {
      name: "WebMD",
      place: "New York City",
      date: "June 2017 – April 2018",
      position: "Senior Web Developer – Intern",
      description:
        "Developing responsive Front-end pages of WebMD Labs & Local health directories. Developed a chatbot that can answer any health, treatment and drug-related questions.",
      skills: ["Node.js", "HTML", "CSS", "Javascript"]
    }
  ],
  skills: [
    {
      title: "languages",
      info: [
        "Javascript",
        "lua",
        "python"
      ],
      icon: "fa fa-code"
    },
    {
      title: "Languages, Technologies, and Frameworks",
      info: [ "Python", "JavaScript", "Nginx", "OpenResty", "Lua", "VueJS", "NodeJS", "HTML", "CSS"],
      icon: "fas fa-laptop-code"
    },
    {
      title: "databases",
      info: ["MongoDB", "PostgreSQL", "Redis", "Elasticache"],
      icon: "fa fa-database"
    },
    {
      title: "Container Orchestration & Tools",
      info: [
        "Docker",
        "Kubernetes",
        "Rancher",
        "ECS",
        "Helm"
      ],
      icon: "fas fa-tools"
    },
    {
      title: "Observability",
      info: [
        "Prometheus",
        "Grafana",
        "DataDog",
        "Cloudwatch"
      ],
      icon: "fas fa-chart-bar"
    }
  ],
  recommendations: [
    {
      title:
          "Kshitij is extremely smart, hard working and very talented. he's a great UI Web Developer. Quick, chipper, knowledgeable, and really easy to work with. Also, he's done things with CSS that impressed everyone. He has the passion to build high quality, Scalable and large web applications. and developing strong web applications following processes that combine practices, efficiency and quality.",
      position: "Associate Director, Development",
      company: "WebMD",
      location: "New York City"
    },
    {
      title:
          "It was a great experience working with Kshitij. Kshitij started as an intern in one of my teams and worked for various other teams and products as a front end developer. He demonstrated great technical skills in developing front end applications. He worked efficiently with senior developers and team members to learn and adopt internal tools and technologies.",
      author: "Madhura Mitra",
      position: "Director, Software Engineering, Capital One",
      company: "WebMD",
      location: "New York City"
    },
    {
      title:
          "It’s hard to believe Kshitij was actually employed as an intern. He was an invaluable asset on multiple high visibility initiatives and is way more seasoned than of someone with his amount of experience would be expected to be. I believe this has to do with his work ethic and level of commitment. He was extended well over the normal length of an internship and juggled school and his sprint deliverables simultaneously. He adapted to our SDLC process quickly, meshed with the team well, and executed upon assignments to successful completion escalating any issues he had to keep on timeline. The quality of his coding skills was noted by colleagues to be most excellent. ",
      author: "Dave Gelinas",
      position: "Senior Director, Product Management, Crosslake Technologies",
      company: "WebMD",
      location: "New York City"
    }
  ]
};

export default info;
