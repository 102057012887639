<template>
  <div class="bg-secondary"
       :class="{
      'bg-dark': !nightMode,
      'text-light': !nightMode,
    }">
    <div class="container py-3">
      <div class="row pt-1 align-items-center">
	      <div class="title2 pt-2 col-xl-6 col-bg-6 col-md-6 col-sm-12 pbelow">
		      <i class="fas fa-code" /> with <i class="fas fa-heart" /> by
		      <a
				    class="badge badge-dark"
				    rel="noopener"
				    href="https://github.com/kshitijmjoshi"
				    aria-label="My GitHub"
		      >
			      {{ name }}
		      </a>
		      using <i class="fab fa-vuejs" />
	      </div>
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <a
		          :href="this.linkedin"
		          target="_blank"
              class="btn btn-outline-secondary mx-2 "
		          aria-label="My LinkedIn"
            >
              <i class="fab fa-linkedin"></i>
            </a>
            <a
		          :href="this.github"
		          target="_blank"
              class="btn btn-outline-secondary mx-2"
		          aria-label="My GitHub"
            >
              <i class="fab fa-github"></i>
            </a>
            <a
		            :href="this.resume"
		            target="_blank"
              class="btn btn-outline-secondary mx-2"
		            aria-label="My Resume"
            >
              <i class="fa fa-address-book"></i>
            </a>
	          <a
			          :href="this.instagram"
			          target="_blank"
			          class="btn btn-outline-secondary mx-2"
			          aria-label="My Instagram"
	          >
		          <i class="fab fa-instagram"></i>
	          </a>
	          <a
			          :href="this.facebook"
			          target="_blank"
			          class="btn btn-outline-secondary mx-2"
			          aria-label="My Facebook"
	          >
		          <i class="fab fa-facebook"></i>
	          </a>
	          <a
			          :href="this.twitter"
			          target="_blank"
			          class="btn btn-outline-secondary mx-2"
			          aria-label="My Twitter"
	          >
		          <i class="fab fa-twitter"></i>
	          </a>
          </div>
        </div>
      </div>
    </div>
	  <div class="mt-auto py-3 text-center ">
		  <div
				  style="color: white;"
		  >
			  <span>© {{ new Date().getFullYear() }} Copyright: {{ name }}</span>
		  </div>
	  </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "Footer",
  data() {
    return {
			name: info.name,
      linkedin: info.links.linkedin,
      github: info.links.github,
      angellist: info.links.angellist,
      resume: info.links.resume,
	    instagram: info.links.instagram,
	    facebook: info.links.facebook,
	    twitter: info.links.twitter,
    };
  },
	props: {
		nightMode: {
			type: Boolean,
		},
	},
  methods: {
    // open(link) {
    //   switch (link) {
    //     case "linkedin":
    //       window.open(this.linkedin, "_blank");
    //       break;
    //     case "github":
    //       window.open(this.github, "_blank");
    //       break;
    //     case "angellist":
    //       window.open(this.angellist, "_blank");
    //       break;
    //     case "resume":
    //       window.open(this.resume, "_blank");
    //       break;
    //   }
    // },
  },
};
</script>

<style scoped>
span {
  font-weight: 500;
}

.btn {
  border-color: white;
  color: white;
}

.btn:hover {
  background-color: white;
  border-color: white;
  color: gray;
}

.btn:focus {
  background-color: white;
  border-color: white;
  color: gray;
}

@media screen and (max-width: 580px) {
  .pbelow {
    padding-bottom: 20px;
    text-align: center;
  }
}
</style>
