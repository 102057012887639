var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3 mx-3"},[_c('div',{staticClass:"card smcard",class:{
        'pcard-dark': _vm.nightMode,
        pcard: !_vm.nightMode,
        'bg-dark3': _vm.nightMode,
      }},[_c('div',{staticClass:"card-body pborder-top"},[_c('h2',{staticClass:"title2"},[_vm._v(_vm._s(_vm.projects.name))]),_vm._v(" "),_c('div',[_c('div',{staticClass:"pb-1 bheight"},_vm._l((_vm.projects.topics),function(tech){return _c('span',{key:tech,staticClass:"badge mr-2 mb-2",class:{ 'bg-dark4': _vm.nightMode }},[_vm._v(_vm._s(tech)+"\n            ")])}),0),_vm._v(" "),_c('p',{staticClass:"title3 m-0 pb-2 pheight pt-1",domProps:{"innerHTML":_vm._s(
              _vm.projects.description && _vm.projects.description.length > 100
                ? _vm.projects.description.substring(0, 105) + '...'
                : _vm.projects.description
            )}})]),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('a',{staticClass:"text-center btn-sm btn btn-outline-secondary no-outline",attrs:{"href":_vm.projects.svn_url + '/archive/master.zip'},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.projects.svn_url + '/archive/master.zip')}}},[_c('i',{staticClass:"fab fa-github"}),_vm._v(" Clone Project\n          ")]),_vm._v(" "),(_vm.projects.url)?_c('a',{staticClass:"text-center btn-sm btn btn-outline-secondary no-outline",attrs:{"href":_vm.projects.html_url,"target":" _blank"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.projects.html_url)}}},[_c('i',{staticClass:"fab fa-github"}),_vm._v(" Repo\n          ")]):_vm._e()]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"pb-3"},[_vm._v("Languages:\n            "),_vm._l((_vm.projects.languages_percent),function(percent,name){return _c('a',{key:percent,staticClass:"badge badge-light card-link",attrs:{"href":_vm.projects.html_url+ '/search?l=' + name,"target":" _blank"}},[_vm._v(_vm._s(name)+": "+_vm._s(((percent/_vm.projects.total_languages_percent)*100).toFixed(2))+"%\n            ")])})],2),_vm._v(" "),_c('div',{staticClass:"card-text"},[_c('a',{staticClass:"text-reset",attrs:{"href":_vm.projects.html_url + '/stargazers',"target":" _blank"}},[_c('span',{staticClass:"card-link mr-4"},[_c('i',{staticClass:"fab fa-github"}),_vm._v(" Stars\n              "),_c('span',{staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm.projects.stargazers_count))])])]),_vm._v(" "),_c('div',[_c('small',{},[_vm._v("Updated "+_vm._s(_vm.setUpdatedAt()))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }